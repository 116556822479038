// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!antd/dist/antd.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-3-1!../node_modules/postcss-loader/src/index.js??postcss!react-phone-input-2/lib/style.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "body {   \r\n  margin: 0;\r\n  padding: 0;\r\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\r\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\r\n    sans-serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\ncode {\r\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\r\n    monospace;\r\n}\r\n\r\n.react-tel-input .form-control {\r\n  height: 35px;\r\n  width: 200px;\r\n}\r\n\r\n.ant-table-tbody > tr .ant-table {\r\n  margin: 0 !important;\r\n}\r\n\r\n.ant-table-tbody > .not-active-row {\r\n  background-color: lightgrey;\r\n}\r\n\r\n.ant-list-split .ant-list-item {\r\n  border-bottom: 1px solid lightgrey;\r\n}\r\n\r\n.site-layout {\r\n  background: #fff;\r\n}\r\n[data-theme=\"dark\"] .site-layout {\r\n  background: #141414;\r\n}\r\n.site-header {\r\n  line-height: 32px;\r\n  height: 32px;\r\n  _position: sticky;\r\n  width: 100%;\r\n  padding: 0 5px;\r\n}\r\n\r\n.row-dragging {\r\n  background: #fafafa;\r\n  border: 1px solid #ccc;\r\n}\r\n\r\n.row-dragging td {\r\n  padding: 16px;\r\n  visibility: hidden;\r\n}\r\n.row-dragging .drag-visible {\r\n  visibility: visible;\r\n}\r\n.row-v-alig-top {\r\n  vertical-align: top !important;\r\n}\r\n.bg-light-green {\r\n  background-color: #f6ffed;\r\n}\r\n\r\n.login_form {\r\n  min-Width: 40%;\r\n  background-Color: #dae2ef;\r\n  position: absolute;\r\n  top: 25%;\r\n  left: 30%;\r\n  remove_padding-Top: 50px;\r\n  border: #c1c1c1 solid 1px;\r\n}\r\n#login_form input:-webkit-autofill {\r\n    -webkit-background-clip: text;\r\n}\r\n.ant-form-item {\r\n  margin-bottom: 8px !important;\r\n}\r\n", ""]);
// Exports
module.exports = exports;
