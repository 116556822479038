import React from "react";
import { Menu, Dropdown } from "antd";
import { useStoreon } from "storeon/react";
import { EditOutlined, UploadOutlined, MenuOutlined } from "@ant-design/icons";
import ShipmentDeleteButton from "./ShipmentDeleteBtn";

const ShipmentListMenu = props => {
  const { dispatch } = useStoreon();

  const handleClick = e => {
    switch (e.key) {
      case "edit":
        dispatch("showForm", {
          component: "shipment",
          action: "edit"
        });
        break;
      case "delete":
        break;

      case "upload":
        dispatch("showUploadForm", {
          dataModel: "shipment",
          id: props.id
        });
        break;

      default:
        return null;
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      <Menu.Item key="edit">
        <EditOutlined />
        Edit
      </Menu.Item>
      <Menu.Item key="delete" title="delete">
        <ShipmentDeleteButton type="menuItem" />
      </Menu.Item>
      <Menu.Item key="upload">
        <UploadOutlined />
        Upload
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      {/* <a href="#"> */}
        {/* <Down /> */}
        <MenuOutlined />
      {/* </a> */}
    </Dropdown>
  );
};

export default ShipmentListMenu;
