import React, { useState, useEffect } from 'react';
import { useStoreon } from "storeon/react";
import { Form, Input, DatePicker, Button, Upload, message, Spin, Row, Col, Space, Divider, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from "moment";

import RegNumInput from "../shared/RegNumInput";
import { AUTH_TOKEN } from "../../constant";

const debug = false;

const API_INVOICE   = process.env.REACT_APP_SERVER_URL + '/invoiceninja/invoices';
const API_DOCUMENTS = process.env.REACT_APP_SERVER_URL + '/invoiceninja/documents';

const getInvoice = async (invoiceId) => {
  const response = await fetch(`${API_INVOICE}/${invoiceId}`);
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

const createInvoice = async (invoiceData, files) => {
debugger;

  try { 
    const createInvoiceResponse = await fetch(API_INVOICE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(invoiceData),
    });
    if (!createInvoiceResponse.ok) throw new Error('Network response was not ok');
  
    const createInvoiceData = await createInvoiceResponse.json();
    const invoiceId = createInvoiceData?.data?.id;
  
    if (!invoiceId) {
      console.log("createInvoice: create invoice, bad answer (no invoice id):", createInvoiceData);
      message.error ("Can`t create invoice");
      return {};
    }

    // Upload documents
/*
    for (const file of files) {
      try {
        const result = await uploadFile (invoiceId, file);
        if (file.status === 'done') {
          const uploadedFile = file.response;
          setFiles([...files, {
            uid: uploadedFile.id,
            name: uploadedFile.filename,
            url: uploadedFile.url,
            invoice_id: invoiceId,
          }]);
        }
        setIsNeedUpdate(true);
        const result = await uploadFiles (invoiceId, files);

      } catch (e) {
        console.log("createInvoice: can`t upload file:", file.name);
        message.error ("can`t upload file: "+file.name);
      }
    }
*/
    const files_result = (files && files.length > 0) ? await uploadFiles (invoiceId, files) : null;

    // Call the onSubmit callback with the created/edited invoice ID

    // onSubmit(invoiceId);
    // message.success('Invoice saved successfully!');

    return createInvoiceData;

  } catch (error) {
      console.error('Error saving invoice:', error);
      message.error('Failed to save invoice.');
  };

  return {};

};

const updateInvoice = async (invoiceId, invoiceData) => {
  const response = await fetch(`${API_INVOICE}/${invoiceId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(invoiceData)
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

// file ////////////////////////////////////////////
/*
const uploadFile = async (p_invoice_id, file) => {
  if (!p_invoice_id) {
    alert('New invoice, no invoice_id - try load later at save whole invoice ops');
    return {};
  }
//////////////////////
//
//    const formData = new FormData();
//    fileList.forEach((file) => {
//      // formData.append('files[]', file);
//      formData.append('file', file);
//    });
//    Object.keys(botArgs).map((key) => formData.append(key, botArgs[key]));
//    //formData.append('data', JSON.stringify(botArgs));
//    setUploading(true);
//
//////////////////////
  const formData = new FormData();
  //formData.append('file', file.originFileObj, file.originalname); // was just file);
  formData.append('file', file);

  const response = await fetch(`${API_INVOICE}/${p_invoice_id}/upload`, {
    method: 'POST',
//    header: formData.getHeaders(),
    body: formData,
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};
*/

// file ////////////////////////////////////////////
const uploadFiles = async (p_invoice_id, files) => {
  console.log('uploadFiles: called');

  if (!p_invoice_id) {
    alert('New invoice, no invoice_id - try load later at save whole invoice ops');
    return {};
  }

  const formData = new FormData();
  (Array.isArray(files) ? files : [files]).forEach((file) => {
    // formData.append('files[]', file);
    formData.append('files', file);
  });

  const response = await fetch(`${API_INVOICE}/${p_invoice_id}/upload`, {
    method: 'POST',
//    header: formData.getHeaders(),
    body: formData,
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

////////////////////////
const deleteFile = async (fileId) => {
  console.log('deleteFile: called');
  const response = await fetch(`${API_DOCUMENTS}/${fileId}`, {
    method: 'DELETE'
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};
// file ////////////////////////////////////////////

////////////////////////////////////////////////////
//
////////////////////////////////////////////////////
const InvoiceForm = ({ initialValues, invoice_id: invoiceId, onSuccess, onClose, setIsNeedUpdate }) => {
  const { auth, tab } = useStoreon("auth", "tab");
  const { selection, ui, dispatch } = useStoreon("ui", "selection");
  const { action, component } = ui.visibleForm;

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
 
  // Use custom method due files and custom component features
  const [isTouched, setIsTouched] = useState();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (component !== "invoice") return;

    setIsUpdating(false);
    setIsTouched(false);

    if (invoiceId) {
      // Edit
      setIsLoading(true);
      getInvoice(invoiceId)
        .then((p_invoice) => {

          //alert('invoice: '+JSON.stringify(p_invoice));
debugger;
          const invoice = p_invoice.data.data;

          form.setFieldsValue({
            client_id: invoice.client_id,
            invoice_id: invoice.id,
            number: invoice.number,
            po_number: invoice.po_number,
            date: moment(invoice.date, 'YYYY.MM.DD'),
          });
          setFiles((invoice.documents||[]).map(file => ({
            uid: file.id,
            name: file.name,
            url: file.url,
          })));
        })
        .catch((error) => {
          message.error(`Error getInvoice: ${error.message}`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // Create new
      setIsLoading(true);
      form.resetFields();
      form.setFieldsValue({
        client_id: initialValues?.client_id,
        invoice_id: null,
        po_number: null,
        number: null,
        date: moment(), // .format('YYYY.MM.DD'),
      });

      setFiles([]);
      setIsLoading(false);
    }
    // we must reload invoices in table view if we not save invoice 
    // but just modify files and close form
    setIsNeedUpdate(false);

  }, [component]);

  useEffect(() => {
    console.log('useEffect: reserved ...'); 
  }, [invoiceId, form]);

  const onFinish = async (values) => {
debugger;

    setIsUpdating(true);
    let result;

    // used only date. remove time and timezone info
    values.date = values.date.format('YYYY-MM-DD');

    if (invoiceId) {
      result = await updateInvoice(invoiceId, values);
    } else {
      result = await createInvoice(values, files);
    }

    setIsUpdating(false);
    dispatch("hideForm", { component: 'invoice' });

    if (result?.data?.id) {
      console.log('Invoice saved');
      if (onSuccess) onSuccess();
    }

  };

  ///////////
  const handleCancel = () => {

    setIsUpdating(false);
    dispatch("hideForm", { component: 'invoice' });

    form.resetFields();
    form.setFieldsValue({
      // client_id: client_id,
      invoice_id: null,
      po_number: null,
      number: null,
      date: moment(), // .format('YYYY.MM.DD'),
    });
    setFiles([]);

    if (onClose) onClose();
  };

  const onUpload = async (args) => {
debugger;
    console.log('onUpload: ', args);
    try {
      setIsUpdating(true);
     
      const { file } = args;
      //alert('onUpload.postponed: '+JSON.stringify(args));
      const invoice_id = form.getFieldValue('invoice_id');
     
/*   
 *   
      const result = await uploadFiles(invoice_id, file);
      if (file.status === 'done') {
        const uploadedFile = file.response;
        setFiles([...files, {
          uid: uploadedFile.id,
          name: uploadedFile.filename,
          url: uploadedFile.url,
          invoice_id: invoiceId,
        }]);
        setIsNeedUpdate(true);
      }
*/   
      // file typeof File ... {...file} not worked
      const answer = await uploadFiles(invoice_id, file);
      if (answer?.data && answer.data.documents) {
     
        const files_saved = answer.data.documents.map(file => ({
          uid: file.id,
          name: file.name,
          url: file.url,
        }));
     
        // we don`t need to add new uploaded files due returned all batch of documents
        // setFiles([...files, ...files_saved]);  
        setFiles(files_saved);  
        setIsNeedUpdate(true);
     
        } else {
          console.log('onUpload: unexpected answer:', answer);
          message.error('Can`t upload documents. '+answer?.error);
        }
      } catch (e) {
         console.log('onUpload: upload files, unexpected error:', e);
         message.error('Can`t upload document(s). '+e);

      } finally {
        setIsUpdating(false);
      }
  };

  const onRemove = async (file) => {
debugger;
    if (file.uid !== undefined) {
      if (!file.url) {
        console.log('onRemove: local file, not saved yet. just remove from array');
        setFiles(files.filter(f => f.uid !== file.uid));        
      }
      else
      try {
        setIsUpdating(true);

        const answer = await deleteFile(file.uid);
        if (answer?.data?.message) {
          setFiles(files.filter(f => f.uid !== file.uid));
          setIsNeedUpdate(true);
        } else {
          console.log('onRemove: deleteFile, unexpected answer:', answer);
          message.error('Can`t delete document. '+answer?.data?.error);
        }
      } catch (e) {
         console.log('onRemove: deleteFile, unexpected error:', e);
         message.error('Can`t delete document. '+e);

      } finally {
        setIsUpdating(false);
      }

    } else {
        console.log('onRemove: unknown id (just added?), skip');
        message.error('Can`t delete document yet.');
    }
  };

  const beforeUpload = (file, filesArray) => {
    // additional checks before upload process if need
debugger;
    console.log('beforeUpload: ', file, filesArray);

    if (!!invoiceId) {
      //alert('beforeUpload: postponed: '+file.name);
      return (true);
    }

    // for multiple mode beforeUpload() calles n-times
    // but setFiles() ommited all except last call
    // setFiles([...files, file]);
    if (filesArray.every((f) => f.uid)) setFiles([...files, ...filesArray]);

    return (false);
  };
  
  const onDownload = (e) => {
    // not triggered at current version of Antd
    // workaroud: use onPreview
    debugger;
  };

  const onPreview = async (file) => {
    debugger;
    if (file.uid && file.url) {

      const url =  `${API_DOCUMENTS}/${file.uid}`;
      // two methods: 

      // 1. direct link
      //window.open(url , '_self');
      //return;

      // 2. for trick for auth headers
      // 
      fetch(url, {
        method: 'GET',
        headers: {
          // actual for direct download form invioiceninja via base api
          // 
          // "X-API-TOKEN": "83TnaCnQn4em11vEyvTtwXA7UYGvG4qFiQ0BkEM9LM3m6q09Tmpd7jpJOpaNFemM",
          //  "X-Requested-With": "XMLHttpRequest"
          //
          // now we use our backend as middleware for secure reason
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', file.name,);
        // link.setAttribute('target','_blank');

        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });

    }
  };

  const uploadButton = (
    <Space>
      <PlusOutlined type="plus" />
      <div style_={{ marginTop: 8 }}>Add files</div>
    </Space>
  );

//        onOk={handleSubmit}
  return (
      <Modal
        title={ invoiceId ? "Edit invoice data" : "Create new invoice" }
        name="invoice_form"
        visible={component === "invoice"}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
      >
<Spin spinning={isLoading}>
  { debug && <b>{JSON.stringify(initialValues)}</b> }
  <Form
    form={form}
    layout="vertical"
    initialValues={initialValues}
    onFinish={onFinish}
    onFieldsChange={(e) => {
      // add your additionaly logic here

      // in the current implementation,
      // the files are immediately sent to the server without the need to press the save button.
      // remove this check if saving method for files is changed
      // or use classic method:
      // Field.Item shouldUpdate + {() => (<Button ... >)}
      if (!isTouched && !e.every((o) => (o.name[0] == "documents")))
        setIsTouched(true);
    }}
  > 
    <Form.Item
      label="Client_id"
      name="client_id"
      readOnly
      hidden={true}
    >
      <Input readOnly={true} />
    </Form.Item>

    {invoiceId && <>
    <Form.Item
      label="Invoice_id"
      name="invoice_id"
      hidden={true}      
    >
      <Input />
    </Form.Item>

  <Row gutter={[8,8]}>
   <Col span={24}>
    <Form.Item
      label="Invoice number"
      name="number"      
      rules_={[{ required: true, message: 'Please input invoice number' }]}
    >
      <Input readOnly={true}/>
    </Form.Item>
    </Col>
  </Row>
    </>}

  <Row gutter={[8,8]}>
   <Col span={12}>
    <Form.Item
      label="Date"
      name="date"
      rules={[{ required: true, message: 'Please input invoice date' }]}
    >
      <DatePicker 
        format='DD.MM.YYYY' 
        disabledDate={(current)  => {
          // Can not select days after today 
           return moment() < current;
        }}
      />
    </Form.Item>
    </Col>
   <Col span={12}>
    <RegNumInput
        style={{width: 200,}}
        label="Po Number"
        name="po_number"
        rules={[{ required: true, message: 'Please input PO Number' }]}
    />
    </Col>
  </Row>
    <Divider>Documents</Divider>
    <Form.Item 
      label_off="Documents:"
      name="documents"
    >
      <Upload
        listType="text"
        accept="pdf"
        multiple={true}
        fileList={files}
        customRequest={onUpload}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
        showUploadList={{
          showPreviewIcon:false,
          showRemoveIcon: ((file) => {/*alert(JSON.stringify(file));*/ return file.url}), // 5.x
        }}
        onDownload={onDownload}
        onPreview={onPreview}
        headers={{
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
        }}
      >
        {files.length >= 10 ? null : uploadButton}
      </Upload>
    </Form.Item>
    <Form.Item shouldUpdate>
      <Button 
         type="primary" 
         htmlType="submit" 
         loading={isUpdating}
         
         disabled_={isUpdating ||
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
         }
         disabled={isUpdating || !isTouched}
      >
        Save
      </Button>
    </Form.Item>
  </Form>
  { debug && <>isUpdating:{isUpdating} touched: {form.isFieldsTouched(["po_number"])}/{form.isFieldsTouched(true)} errors: {form.getFieldsError().filter(({ errors }) => errors.length).length}</> }
</Spin>
      </Modal>
);
}

export default InvoiceForm; 
