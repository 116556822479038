import { AUTH_TOKEN, REFRESH_TOKEN } from "../constant";

// ReferenceError: fetch is not defined
// prior 18.X fetch is module

// import fetch from 'node-fetch';
//const fetch = (...args) => import('node-fetch').then(({default: fetch}) => fetch(...args));
//global.fetch = fetch;

//const get_fetch_provider = () => window.fetch_original ?? fetch;

// save original fetch
if (!window.fetch_original) window.fetch_original = fetch;

const { configureRefreshFetch, fetchJSON } = require('refresh-fetch');
const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;

////////////////////////
const retrieveToken = () => { 
  //const jwt_token = localStorage.getItem(AUTH_TOKEN)||"";
  //console.log('retrieveToken: jwt:', jwt_token);
  return localStorage.getItem(AUTH_TOKEN);
}
const retrieveRefreshToken = () => {
  //const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  //console.log('retrieveToken: refresh:', refresh_token);
  return localStorage.getItem(REFRESH_TOKEN);
}

const saveToken = (jwt_token, refresh_token) => {
  //console.log('saveToken: refresh: ', refresh_token, ' jwt:', jwt_token);
  localStorage.setItem(AUTH_TOKEN, jwt_token);
  localStorage.setItem(REFRESH_TOKEN, refresh_token);
};

const clearToken = () => { 
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

////////////////////////
const getResponseBody = (response)  => {
  const contentType = response.headers.get('content-type')
  return contentType && contentType.indexOf('json') >= 0
    ? response.clone().text().then(tryParseJSON)
    : response.clone().text()
}

const tryParseJSON = (json) => {
  if (!json) {
    return null
  }
  try {
    return JSON.parse(json)
  } catch (e) {
    throw new Error(`Failed to parse unexpected JSON response: ${json}`)
  }
}

const  ResponseError = (e, status, response) => {
  e.name = 'ResponseError';
  e.status = status;
  e.response = response;
}

// failed
// ResponseError.prototype = Error.prototype;

const checkStatus = ({response, body}) => {
  if (response.ok) {
    return response;
  } else {
    // throw new ResponseError(response.status, response);
    const e =  new Error();
    ResponseError(e, response.status, response);
    e.body = body;
    throw e;
  }
}

const fetchWithToken = async (url, options = {}) => {
debugger;
  const token = retrieveToken()
  console.log('fetchWithToken: header.auth:', options.headers?.Authorization?.substring(0, 20));

  let optionsWithToken;
  if (token != null) {
    optionsWithToken = { ...options, headers: {...options.headers, 
        Authorization: `Bearer ${token}`
      }
    };
  } else 
    optionsWithToken = options;
  
//  window.fetch = window.fetch_original;
//  const ret = await fetchJSON(url, optionsWithToken);
//  window.fetch = fetch_with_refresh;
//  return ret.response;

  //console.log('fetchWithToken: optionsWithToken:', optionsWithToken);

  return window.fetch_original(url, optionsWithToken)
    .then((response) => {
       // console.log('fetchWithToken: fetch_original: got response');
       return getResponseBody(response).then(body => ({
         response,
         body
       }));
    })
    .then(checkStatus)
}

//'Token has expired'
//"Incorrect or expired JWT Token"
const shouldRefreshToken = error => {
  const res = error.response.status === 401 &&
  //error.body.message === 
  error.body.error === "Incorrect or expired JWT Token";
  
  console.log('shouldRefreshToken:', res? "true" : "false");

  if (!res) {
    console.log('why? status:', error.response.status, "message:", error.body.message);
    if (!error.response?.status || !error.body?.message)
      console.log('skip refresh due bad unknown error:', error);
  }
  return res;
};

const refreshToken = () => {
    console.log('refreshToken: called');
    console.log('refresh_token:', retrieveRefreshToken());
    
    return window.fetch_original(REACT_APP_SERVER_URL + "/auth/refresh-token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: retrieveRefreshToken(), })})
    .then(res => { 
      //console.log("res: raw:", res);
      //console.log("json?", (res.json ? "yes" : "no"));
      return (res.json ? res.json() : res);
    })
    .then(res => {
      console.log('refreshToken: refreshed, saving to store ...');
      //console.log("raw:", res);
      const t = res.access_token ?? {};
      saveToken(t.jwt_token, t.refresh_token);
      return res;
    })
    .catch(error => {
      // Clear token and continue with the Promise catch chain
      console.log('refreshToken: got error, clearing store ...', error);
      clearToken()
      throw error
    })
};

export const fetch_with_refresh = configureRefreshFetch({
  fetch: fetchWithToken,
  shouldRefreshToken,
  refreshToken
});

window.fetch = fetch_with_refresh;

/*
module.exports = {
  fetch_with_refresh,
}
*/
