import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import gql from "graphql-tag";
import { useStoreon } from "storeon/react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { Form, Input, Tag, Space, Table, Radio, Button, Typography } from "antd";

import { ArrowRightOutlined, MailOutlined, DownloadOutlined } from '@ant-design/icons';

import UploadCert from "./UploadCert.js";

import RegNumInput from "../../shared/RegNumInput";
const RegNumItem = RegNumInput;

const { Text, Link } = Typography;

//////////////////////////////////////////////////
const TracksList = ({form, input_ref, company_id, onChange, ...otherProps}) => {
  const { loading, error, data } = useQuery(GET_TRUCKS, { variables: {clientId: company_id }, skip: !company_id });
  
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

//      {data.trucks.map((r, i) => <Tag onClick={(e) => form.setFieldValue("reg_num", {r.reg_number})} >{r.reg_number}</Tag>) }
//      {data.trucks.map((r, i) => <Tag onClick={(e) => form.setFieldsValue({reg_num: e.target.innerText}) } >{r.reg_number}</Tag>) }
//      {data.trucks.map((r, i) => <Tag onClick={(e) => input_ref.current.input.value = e.target.innerText } >{r.reg_number}</Tag>) }

//      {data.trucks.map((r, i) => <Tag key={r.reg_number} onClick={(e) => form.setFieldValue("reg_number", e.target.innerText) } >{r.reg_number}</Tag>) }

  if (data?.trucks.length) 
    return  (<Space wrap><b>Trucks: </b> 
      {data.trucks.map((r, i) => <Tag key={r.reg_number} onClick={(e) => onChange(e.target.innerText) } >{r.reg_number}</Tag>) }
   </Space>);
  else 
    return ('NO TRUCKS ... please enter manualy:');  
}

//////////////////////////////////////////////////
const CertificatesPage = () => {
  const [ form ] = Form.useForm();
  const { dispatch } = useStoreon("selection");
  const [ company, setCompany ] = useState({});
  const [ regNum, setRegNum ] = useState();
  const [ regNumChecked, setRegNumChecked ] = useState(false);
  const [ stage, setStage ] = useState(1);
  const [ already, setAlready ] = useState(false);
  const [ replacement, setReplacement ] = useState(false);
  const [ certType, setCertType ] = useState("paid");
  const [ fileInfo, setFileInfo ] = useState();
  const [ isCertFreeAcn, setIsCertFreeAcn ] = useState(false);
  const [ startUpload, setStartUpload ] = useState(false);
  const [ operation, setOperation ] = useState();

  const RegNumItem_ref = useRef(null);

  // get certificates by registration number for today
  const [ getCertsByRNtoday, { loading: cert_loading, data: cert_data, error: cert_error } ] = useLazyQuery(GET_CERTIFICATES_TODAY);
  const { loading, error, data } = useQuery(GET_COMPANIES);

  ///////////
  useEffect(() => {
    switch (stage) {
      case 1:
          form.setFieldValue("reg_number", '');
          setRegNumChecked(false);
          setAlready(false);
          // done
          setCompany({});
          setRegNum();
          setReplacement(false);
          setCertType("paid");
          setFileInfo();
          setIsCertFreeAcn(false);
          setStartUpload(false);
          setOperation();
          break;
      case 2:
          if (RegNumItem_ref?.current) RegNumItem_ref.current.focus();
          setRegNumChecked(false);
          setAlready(false);
          break;
    }
  }, [stage]);

  // got certs for reg number
  useEffect(() => {
    setRegNumChecked(true);
    if (cert_data?.crt_files.length > 0) setAlready(true);
  }, [cert_data]);

  const onChangeCompany = (e) => {
    console.log('radio checked', e.target.value);
    const target_company = data.companies.find((d) => d.id == e.target.value);
    setCompany(target_company?target_company : {});
    setStage(1);
  };

  const onChangeRegNum = (e) => {    
//    if (e.target.value.length === 0) return;
    setRegNum (e.target.value);
    // new number not checked yet
    setRegNumChecked(false); 
    console.log('regnum:', e.target.value);
  };

  const onSelectFile = (e) => {
    console.log('file:', e);
    setFileInfo (e);
    setStage(5);  
  };

  const onFinishHandler = (e) => {
    console.log('finish:', e);
    setOperation(e);
    setStage(stage+1);  
    setStartUpload(true);
  };

  const onDone = (e) => {
    console.log('done:', e);

    // temporary swithc off for debug
    // setStage(1);  
    setStartUpload(false);

  }
  ///////////
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  ///////////
  return (
<div className="site-layout-content" style={{maxHeight: '100%', overflow: "auto", debug_border: 'red solid 1px'}}>
    <Form layout="vertical" form={form}>

    <Space direction="vertical" size={2}>
    <Radio.Group onChange={onChangeCompany} value={company.id} optionType="button" buttonStyle="solid" style={{ marginTop: 2 }}>
      <Space direction="vertical" size={2}>
        {data.companies.map((r, i) => <Radio key={r.id} value={r.id}>{r.name}</Radio>) }
        <Button 
          disabled={!company.id} 
          icon={<ArrowRightOutlined />} 
          style={{ marginTop: 8 }}
          onClick= {((e) =>  { 
            setStage(2);
            if (RegNumItem_ref?.current) RegNumItem_ref.current.focus();
          })}
        >Next { company.id && <> ... with  <b>{company.short_name}</b></>}</Button>
      </Space>
    </Radio.Group>

    { /* Registration number and checking */ }
    { /* that the certificate already exists */ }
    { /* /////////////////////////////////// */ }
    {company.id && stage > 1 &&
      <>
      <TracksList form={form} input_ref={RegNumItem_ref} company_id={company.id} onChange={(v) => {setRegNum(v);}} />
      <RegNumItem
          input_ref={RegNumItem_ref}
          form={form}
          label="Reg number"
          name="reg_number"
          _dependencies={["company_id"]}
          disabled={stage < 2}
          country_id={company.country_id}
          company_id={company.id}
          onChange={onChangeRegNum}
          value={regNum}
      />
      {already && <>
          <Text type="danger">This certificate already has been processed! Are you trying to issue the replacement?</Text>
          <Button
            style={{ marginTop: 8 }}
            onClick= {(e) =>  { setReplacement(true); }}
            disabled={replacement}
          >Yes
          </Button>
          </>
      }
      <Form.Item 
       shouldUpdate
      >
        {() => (
          <Button
            style={{ marginTop: 8 }}
            icon={<ArrowRightOutlined />}
            loading={cert_loading}
            onClick= {((e) =>  { 
              // when loaded 
              if (!regNumChecked) {
                const opts = {variables: { 
                  clientId:   company.id,
                  regNum:     RegNumItem_ref.current.input.value,
                  today_date: moment().startOf('day'),
                }};
                alert(JSON.stringify(opts));
                getCertsByRNtoday (opts);
              } else {
                // alert('next'); 
                setStage(4);
              } 
            })}
            disabled={
              (already && !replacement) ||
              !regNum ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
          Next 
              { (regNum && !regNumChecked && !form.getFieldsError().filter(({ errors }) => errors.length).length) && <> ... <b>check?</b></>}
              { regNumChecked && <> ... with  <b>{regNum}</b></>}
          </Button>
        )}
      </Form.Item>
      </>
    }

    { /* Select | Drag file */ }
    { /* /////////////////////////////////// */ }
    {stage > 3 && 
       <UploadCert 
          onSelectFile={(e) => onSelectFile(e)} 
          botArgs={{
            client_id: company.id, 
            certType:  certType,
            regNum:    regNum, 
            mode:      operation, 
          }}          
          startUpload={startUpload}
       /> 
    }

    { /* Paid | Free */ }
    { /* ////////////////////////////////// */ }
    {stage > 4 &&  <>
      <Radio.Group onChange={(e) => { setCertType(e.target.value); setIsCertFreeAcn(false);}} defaultValue={certType} optionType="button" buttonStyle="solid" style={{ marginTop: 2 }}>
        <Space direction="horizontal" size={2}>
          <Radio value="paid">paid</Radio>
          <Radio value="free">free</Radio>
        </Space>
      </Radio.Group>
      { certType == "free" && <>
      <Text type="danger">You are about to generate free of charge certificate!?</Text>
      <Button
        style={{ marginTop: 8 }}
        onClick= {(e) =>  { setIsCertFreeAcn(true); }}
      >Ok</Button>
      </>
      }

        <Button 
          disabled={certType == "free" && !isCertFreeAcn } 
          icon={<ArrowRightOutlined />} 
          style={{ marginTop: 8 }}
          onClick= {((e) =>  { 
            setStage(6);
          })}
        >Next { <> ... as <b>{certType}</b></>}</Button>

     </>
    }
    { /* Email | Download */ } 
    { /* ////////////////////////////////// */ }
    {stage > 5 &&  <>
      <Space direction="horizontal" size={2}>
      <Button
        name="email"
        icon={<MailOutlined />}
        style={{ marginTop: 8 }}
        onClick={ (e) => { onFinishHandler("email"); } }
      >Email</Button>
      <Button
        name="download"
        icon={<DownloadOutlined />}
        style={{ marginTop: 8 }}
        onClick={ (e) => { onFinishHandler("download"); } }
      >Download</Button>
      </Space>
    </>
    }

    {stage > 6 &&  <>
      <Button
        style={{ marginTop: 8 }}
        onClick={ (e) => onDone(e) }
      >Done</Button>
    </>
    }
    </Space>

{/*              !form.isFieldsTouched(true) || */}

{/*
      <Row>
        <Col span={24}>
          <Table
            pagination={data?.companies?.length > 1}
            rowKey="id"
            columns={columns}
            //dataSource={data && data.companies}
            dataSource={data.companies}
            size="small"
            _rowClassName={(record) => record.active ? '' : 'not-active-row' }
            onExpand={(_, r) => {
              //dispatch("select", { model: "company", id: r.id });
              //alert(JSON.stringify(r));
            }}
            expandedRowRender={record => (
	      <TransactionsRulesTable company_id={record.id} />
            )}
          />
        </Col>
      </Row>
*/}
    <div style={{height: "100px"}}> </div>
    </Form>
</div>
  );
};

// companies_view?
const GET_COMPANIES = gql`
  query Companies {
    companies: companies (
	order_by: [{ name: asc }]
    ) {
      id
      name
      short_name
      country_id
    }
  }
`;

const GET_TRUCKS = gql`
query Trucks ($clientId: Int!) {
    trucks (
        where: { company_id: { _eq: $clientId}},
	   order_by: [{ reg_number: asc }]
    ) {
      reg_number
    }
  }
`;

// use $today due bad date/time capabilities in hasura v2 
const GET_CERTIFICATES_TODAY = gql`
query CertsByRNToday ($clientId: Int!, $regNum: String!, $today_date: timestamptz!) {
  crt_files (
    where: {_and: [ 
      {client_id: { _eq: $clientId }},    
      {gosnomer: { _eq: $regNum }},    
      {f_date: { _gte: $today_date }}
    ]},
    order_by: [{ n_order: asc }]
  ) {
    id
    reg_num: gosnomer
    f_date
    n_order
  }
}
`;

export default CertificatesPage;

/*
    {stage > 5 &&  <>
      <Radio.Group onChange={(e) => { setCertType(e.target.value); setIsCertFreeAcn(false);}} defaultValue={certType} optionType="button" buttonStyle="solid" style={{ marginTop: 2 }}>
        <Space direction="horizontal" size={2}>
          <Radio value="paid">paid</Radio>
          <Radio value="free">free</Radio>
        </Space>
      </Radio.Group>
      { certType == "free" && <>
      <Text type="danger">You are about to generate free of charge certificate!?</Text>
      <Button
        style={{ marginTop: 8 }}
        onClick= {(e) =>  { setIsCertFreeAcn(true); }}
      >Ok</Button>
      </>
      }

        <Button 
          disabled={certType == "free" && !isCertFreeAcn } 
          icon={<ArrowRightOutlined />} 
          style={{ marginTop: 8 }}
          onClick= {((e) =>  { 
            setStage(7);
          })}
        >Next { <> ... as <b>{certType}</b></>}</Button>

     </>
    }

*/