export const STATUS_DRAFT     = 1;
export const STATUS_SENT      = 2;
export const STATUS_PARTIAL   = 3;
export const STATUS_PAID      = 4;
export const STATUS_CANCELLED = 5;
export const STATUS_REVERSED  = 6;
export const STATUS_OVERDUE   = -1; // status < 4 || < 3 && !is_deleted && !trashed() && due_date < now()
export const STATUS_UNPAID    = -2; // status < 4 || < 3 && !is_deleted && !trashed()

/*
 * 4 show?
export const status_decode = {
  [STATUS_ACTIVE]:   'active', 
  [STATUS_ARCHIVED]: 'archived',
  [STATUS_DELETED]:  'deleted'
};
*/ 

/*
 * old?
export const INVOICE_STATUS_DRAFT     = 1;
export const INVOICE_STATUS_SENT      = 2;
export const INVOICE_STATUS_PARTIAL   = 3;
export const INVOICE_STATUS_PAID      = 4;
export const INVOICE_STATUS_CANCELLED = 5;
export const INVOICE_STATUS_REVERSED  = 6;
export const INVOICE_STATUS_OVERDUE   = -1; // status < 4 || < 3 && !is_deleted && !trashed() && due_date < now()
export const INVOICE_STATUS_UNPAID    = -2; // status < 4 || < 3 && !is_deleted && !trashed()
*/ 

export const INVOICE_STATUS_DRAFT     = 1;
export const INVOICE_STATUS_SENT      = 2;
export const INVOICE_STATUS_VIEWED    = 3;
export const INVOICE_STATUS_APPROVED  = 4;
export const INVOICE_STATUS_PARTIAL   = 5;
export const INVOICE_STATUS_PAID      = 6;
export const INVOICE_STATUS_OVERDUE   = -1; // status < 4 || < 3 && !is_deleted && !trashed() && due_date < now()
export const INVOICE_STATUS_UNPAID    = -2; // status < 4 || < 3 && !is_deleted && !trashed()

export const invoice_status_decode = {
  [INVOICE_STATUS_DRAFT]:     'draft',
  [INVOICE_STATUS_SENT]:      'sent',
  [INVOICE_STATUS_VIEWED]:    'viewed',
  [INVOICE_STATUS_APPROVED]:  'approved',
  [INVOICE_STATUS_PARTIAL]:   'partial',
  [INVOICE_STATUS_PAID]:      'paid',
  [INVOICE_STATUS_OVERDUE]:   'overdue',
  [INVOICE_STATUS_UNPAID]:    'unpaid'
};

export const invoice_status_style = {
  [INVOICE_STATUS_UNPAID]:    {color: 'rgb(255 255 255)', backgroundColor: 'rgb(59 130 246' },
  [INVOICE_STATUS_SENT]:      {color: 'rgb(59 130 246)',  backgroundColor: 'rgb(191 219 254' },
  [INVOICE_STATUS_PAID]:      {color: 'rgb(16 185 129)',  backgroundColor: 'rgb(209 250 229' }
}

export const invoice_status_level = {
  [INVOICE_STATUS_SENT]:     'info',
  [INVOICE_STATUS_VIEWED]:   'warning',
  [INVOICE_STATUS_APPROVED]: 'success',
  [INVOICE_STATUS_PARTIAL]:  'primary',
  [INVOICE_STATUS_PAID]:     'success',
};

export const CURRENCY_DOLLAR = 1;
export const CURRENCY_EURO   = 3;
export const CURRENCY_RUBLE  = 55;

export const currency_decode = { 
  [CURRENCY_DOLLAR]: 'USD', 
  [CURRENCY_EURO]:   'EURO', 
  [CURRENCY_RUBLE]:  'RUB' 
};

/*
const currency_formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // These options can be used to round to whole numbers.
  trailingZeroDisplay: 'stripIfInteger'   // This is probably what most people
                                          // want. It will only stop printing
                                          // the fraction when the input
                                          // amount is a round number (int)
                                          // already. If that's not what you
                                          // need, have a look at the options
                                          // below.
  //minimumFractionDigits: 0, // This suffices for whole numbers, but will
                              // print 2500.10 as $2,500.1
  //maximumFractionDigits: 0, // Causes 2500.99 to be printed as $2,501
});
*/

export const currency_formatters = { 
  [CURRENCY_DOLLAR]: new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}),
  [CURRENCY_EURO]:   new Intl.NumberFormat('en-US', {style: 'currency', currency: 'EUR'}),
  [CURRENCY_RUBLE]:  new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'})
};

export const getIdFromAlias = ($status) => {
  switch ($status) {
      case 'draft':
          return INVOICE_STATUS_DRAFT;
      case 'sent':
          return INVOICE_STATUS_SENT;
      case 'viewed':
          return INVOICE_STATUS_VIEWED;
      case 'approved':
          return INVOICE_STATUS_APPROVED;
      case 'partial':
          return INVOICE_STATUS_PARTIAL;
      case 'overdue':
          return INVOICE_STATUS_OVERDUE;
      case 'unpaid':
          return INVOICE_STATUS_UNPAID;
      default:
          return false;
  }
};

export const isPayable = ($this) => {
  if ($this.is_deleted || $this.status_id === STATUS_PAID || $this.balance < 0) {
      return false;
  } else if ($this.status_id === STATUS_DRAFT && $this.is_deleted === false) {
      return true;
  } else if ($this.status_id === STATUS_SENT && !$this.is_deleted && $this.balance > 0) {
      return true;
  } else if ($this.status_id === STATUS_PARTIAL && !$this.is_deleted && $this.balance > 0) {
      return true;
  } else {
      return false;
  }
};

export const isRefundable = ($this) => {
  if ($this.is_deleted) return false;

  if (($this?.amount - $this.balance) === 0) return false;

  return true;
};

export const isPartial = ($this)  => {
  return $this.status_id >= STATUS_PARTIAL;
};

export const hasPartial = ($this) => {
  return ($this.partial && $this.partial > 0) === true;
};

export const trashed = ($this) => {
  if ($this.client && $this.client.trashed()) {
      return true;
  }
  // TODO: ?
  // return parentTrashed();
  return false;
};