import React, { useState, useEffect } from 'react';
import { Table, Badge, Button, Modal, message, Select, Space, Tag, Typography, DatePicker, Input } from 'antd';
import { UploadOutlined, FileOutlined, PaperClipOutlined } from "@ant-design/icons";

import { css } from '@emotion/css';
import moment from "moment";
import { useStoreon } from "storeon/react";

import { isAdmin } from "../../auth/authHelper";
import InvoiceForm from './InvoiceForm';  
import FormBtn from "../shared/FormBtn";

import { AUTH_TOKEN } from "../../constant";

import { currency_formatters, invoice_status_decode, invoice_status_style } from './InvoiceConstants'; 

const { RangePicker } = DatePicker;

const debug = false;
const API_MAX_INVOICES_PER_PAGE = 32000;

const tableCSS = css({});

/*
const tableCSS = css({
  margin: '5px 5px',
  backgroundColor: 'white',

  '& table': {
    borderCollapse: 'collapse'
  },

  '& thead > tr > th': {
    backgroundColor: 'rgb(41, 138, 171)',
    color: 'white',
  },

  '& thead > tr': {
    borderWidth: '1px',
    borderColor: 'rgb(229, 231, 235)',
    borderStyle: 'solid'
  }
});
*/

/*
const tagCSS = css({
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.badge-success {
    --tw-bg-opacity: 1;
    background-color: rgb(209 250 229 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(16 185 129 / var(--tw-text-opacity));
}
.badge-primary {
    --tw-bg-opacity: 1;
    background-color: rgb(191 219 254 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
}
.badge-warning {
    --tw-bg-opacity: 1;
    background-color: rgb(59 130 246 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(217 119 6 / var(--tw-text-opacity));
}
.badge {
    display: inline-flex;
    align-items: center;
    border-radius: 9999px;
    padding: .125rem .625rem;
    font-size: .75rem;
    font-weight: 500;
    line-height: 1rem;
}
});
*/

const API_INVOICE = process.env.REACT_APP_SERVER_URL + '/invoiceninja/invoices';

const get_client_by_id = (clients, id) => {
  return Array.isArray(clients) ? clients.find((record) => record.id == id) : null;
}

/////
/*
 * use it if we'll want to add documents right form table (as UploadForm)
 *
  <a href="#" onClick={onClick} >
  ...
  </a>
*/

const Attachments = (props) => {
 const { count, onClick, ...otherProps} = props;
 return (
  <Badge 
   title={"Attached "+count+" files"}
   status="success" 
   size="default" 
   count={count} 
   style={{ float: "right" }}
  >
   <PaperClipOutlined style={{ fontSize: '1.2em'}} {...otherProps}/>
  </Badge>
 );
}

/*
export const FilesIcon = props => {
  // use invoice data for compute nesesery info ...
  // 
  const { dispatch } = useStoreon("selection");

  const { id, dataModel } = props; // aggregate amount of files per id

  const handleClick = () => {
    dispatch("showUploadForm", { dataModel: dataModel, id: id });
  };
  const count = typeof props.count === "number"? props.count : -1;

  //return <Attachments count={count || data?.files.aggregate.count} onClick={handleClick} />;
  return <Attachments count={count} onClick={handleClick} />;

};
*/

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_TABLE_PARAMS = {
    filter: {  },
    sorter: { field: "number", order: "descend" },
    pagination: {
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      showSizeChanger: false,
      total: 0,
      showTotal_: (total) => `Total ${total} invoices`,
      showTotal__: (total, range) => `Showing ${range[0]} to ${range[1]} out of ${total} invoices`,
      itemRender_: (_, type, originalElement) => {
        if (type === 'prev') return <a>&laquo;Previous</a>;
        else if (type === 'next') return <a>Next&raquo; </a>;
        return originalElement;
      },
    }};


////////////////////////////////
const InvoiceTable = ({clients, client_idx: p_client_idx }) => {
  const { auth } = useStoreon("auth");
  const { dispatch } = useStoreon();
  const [data, setData] = useState([]);
  const [sort, setSort] = useState('name|asc');
  const [currency, setCurrency] = useState();
  const [invoiceId, setInvoiceId] = useState();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);

  console.log('InvoiceTable: begin, p_client_idx:', p_client_idx, " id=", clients[p_client_idx]?.id);

  const [isLoading, setIsLoading] = useState(false);
//  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function for downloading data from the server
  const fetchInvoices = async () => {
    setIsLoading(true);
    try {

      const client_id = clients[p_client_idx]?.id;
      if (client_id === null || client_id === undefined) throw new Error('Missed client id');

      console.log('fetchInvoices: '+p_client_idx+' => '+client_id);
 
      const url = `${API_INVOICE}?page=1&per_page=${API_MAX_INVOICES_PER_PAGE}&client_id=${client_id}&sort=${sort}`;

      console.log('fetchInvoices: fetch:', url);
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        }
      });
      if (!response.ok) throw new Error('Network response was not ok');

      const { data: jsonResponse } = await response.json();
      if (!jsonResponse?.data || !jsonResponse?.meta?.pagination) throw new Error('Bad response structure');

      setData(jsonResponse.data);
      setTableParams({ ...tableParams, pagination: { ...tableParams.pagination, total: jsonResponse.meta.pagination.total }});

    } catch (error) {
      console.error('Error fetching invoices:', error);
      setData({error: error});      
    } finally {
      setIsLoading(false);
      // do it only for success ops, but we don`t want looping
      setIsNeedUpdate(false);
    }
  };

  // Loading data on first render and page change
  useEffect(() => {
    if (!clients?.length && (p_client_idx === null || p_client_idx === undefined) && !clients[p_client_idx]) return;
    fetchInvoices();
  }, [p_client_idx]);

  useEffect(() => {
     if (isNeedUpdate) {
       //alert('isNeedUpdate');
       fetchInvoices();
     }
  }, [isNeedUpdate]);

  //
/* 
  const handlePaginationChange = (newPagination) => {
    alert('handlePaginationChange')
    setPagination(newPagination);
  };
*/
  const handleTableChange = (pagination, filters, sorter, extra) => {
//    alert('handleTableChange')
    console.log('params', pagination, filters, sorter, extra);
    setTableParams({pagination: pagination, filters: filters, sorter: sorter, extra: extra });
  };
  const columns = [
/*
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },

* in ADM mode - show this column?
*
*
    {
      title: 'Client Name',
      // if we will save the results of searching for invoices and combine them into one array with clients data
      // dataIndex: ['client', 'name'],
      key: 'client_name',
      render: (_, record) => (get_client_by_id(clients, record.client_id)||{}).display_name,
    },
*/
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'number',
      key: 'number',

      sortOrder: tableParams.sorter?.field === 'number' && tableParams.sorter?.order,
      sorter: (a, b) => {
        let A = Number(a.number); let B = Number(b.number);
        if (A < B) { return -1; } if (A > B) { return 1; } return 0;
      },

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
        onKeyDown={(e) => e.stopPropagation()}
        value={tableParams.filters?.["number"] || null}
        style_={{width: "100px"}}
        allowClear={true}
        onChange={(value_) => {
          const value = value_.target.value;
          setSelectedKeys(value?[value]:[]);          
          if (confirm) confirm({ closeDropdown: false });
        }}
        onPressEnter={() => {if (confirm) confirm({ closeDropdown: true });}}

      />,
      filteredValue: tableParams.filters?.["number"] || null,
      onFilter: (value, record) => { 
        console.log('onFilter:', value);
        return record.number.toUpperCase().includes(value.toUpperCase())
      },

    },
    {
      title: 'PO NUMBER',
      dataIndex: 'po_number',
      key: 'po_number',

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
        onKeyDown={(e) => e.stopPropagation()}
        value={tableParams.filters?.["po_number"] || null}
        style_={{width: "100px"}}
        allowClear={true}
        onChange={(value_) => {
          const value = value_.target.value;
          setSelectedKeys(value?[value]:[]);          
          if (confirm) confirm({ closeDropdown: false });
        }}
        onPressEnter={() => {if (confirm) confirm({ closeDropdown: true });}}

      />,
      filteredValue: tableParams.filters?.["po_number"] || null,
      onFilter: (value, record) => { 
        console.log('onFilter:', value);
        return record.po_number.toUpperCase().includes(value.toUpperCase())
      },

    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      render: (value) => currency_formatters[clients[p_client_idx].settings.currency_id].format(value),

      sortOrder: tableParams.sorter?.field === 'amount' && tableParams.sorter?.order,
      sorter: (a, b) => {
        let A = Number(a.amount);
        let B = Number(b.amount);
        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }
        return 0;
      },

    },
    {
      title: 'INVOICE DATE',
      dataIndex: 'date',
      key: 'date',
      render: (value) => moment(value).format('DD/MMM/YYYY'), // HH:mm:ss'),

      sortOrder: tableParams.sorter?.field === 'date' && tableParams.sorter?.order,
      sorter: (a, b) => {
        let A = a.date; let B = b.date;
        if (A < B) { return -1; } if (A > B) { return 1; } return 0;
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => 
       <RangePicker 
        value={tableParams.filters?.date?.[0] || []}
        allowClear={true}
        onChange={(value) => {
//          setTableParams((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "created_at": value?[value]:null}}));
          setSelectedKeys(value?[value]:[]);
          if (confirm) confirm({ closeDropdown: true });
        }}
      />,
      filteredValue: tableParams.filters?.["date"] || [],
      onFilter: (value, record) => { 
      return moment(record.date).isBetween(value[0]._d, value[1]._d, 'day', '[]')
      }

    },
    {
      title: 'NOTES',
      dataIndex: 'public_notes',
      key: 'public_notes',
      render: (v) => <div dangerouslySetInnerHTML={{ __html: v }} />,

      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <Input
        onKeyDown={(e) => e.stopPropagation()}
        value={tableParams.filters?.["public_notes"] || null}
        style_={{width: "100px"}}
        allowClear={true}
        onChange={(value_) => {
          const value = value_.target.value;
          setSelectedKeys(value?[value]:[]);          
          if (confirm) confirm({ closeDropdown: false });
        }}
        onPressEnter={() => {if (confirm) confirm({ closeDropdown: true });}}

      />,
      filteredValue: tableParams.filters?.["public_notes"] || null,
      onFilter: (value, record) => { 
        console.log('onFilter:', value);
        return record.public_notes.toUpperCase().includes(value.toUpperCase())
      },

    },
    {
      title: 'Documents',
      key: 'documents',
      render: (_, record) => record.documents?.length > 0 ? <Attachments count={record.documents.length} onClick={console.log} /> : <></>,
    },
    {
      title: 'Status',
      dataIndex: 'status_id',
      key: 'status_id',
      render: (value) => <Tag style={invoice_status_style[value]}>{invoice_status_decode[value]}</Tag>,
      render_: (value) => <span style={{color: invoice_status_style[value].color}}>{invoice_status_decode[value]}</span>,
    },
    {
      key: 'edit',
      dataIndex: 'id',
      //
      // Can be converted to a FormBtn
      render: (value) => <Button type="link" onClick={() => { showModal(value); } }>Edit</Button>,
    },
  ];

  // Show/hide modal with invoice form
  const showModal = (id) => {

    setIsNeedUpdate(false);    
    setInvoiceId(id); 

//    setIsModalVisible(true);
    dispatch("showForm", { component: 'invoice' });
  };


/*
 * move to form
 *
  const hideModal = () => {
    setIsModalVisible(false);

    if (isNeedUpdate) {
      // Reload data after changing invoice documents 
      fetchInvoices(tableParams.pagination.current, tableParams.pagination.pageSize); 
    }
  };
*/

  /////////// 
  const handleSuccess = () => {
    message.success('Invoice '+ invoiceId ? 'saved' : 'created');
//    setIsModalVisible(false);
    setInvoiceId();

    // Reload data after creating a new or changing invoice
    fetchInvoices(tableParams.pagination.current, tableParams.pagination.pageSize); 
  };

  //
  /*
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
        // Table.SELECTION_ALL,Table.SELECTION_INVERT, Table.SELECTION_NONE,
        {
          key: 'data-all',
          text: 'Select All Data',
          onSelect: changableRowKeys => {
            onSelectChange(filteredData.map((a) => a.id));
          },
        },
        {
          key: 'data-none',
          text: 'Clear All Data',
          onSelect: changableRowKeys => {
            onSelectChange([]);
            //setSelectedRowKeys([]);
          },
        },
    ],
    hideDefaultSelections: true,
  };
  */

  //
  if (!data) return;
  if (data.error) return 'An error was received when retrieving information about invoices from the server';

  return (
    <>

      { debug && p_client_idx !== null &&  p_client_idx !== undefined && <>{p_client_idx}:{clients[p_client_idx]?.id}</> }

      <Space direction="vertical">

      <Button disabled={isLoading} type="primary" onClick={() => { showModal(); }} style={{ marginBottom: 16, backgroundColor_: 'rgb(41, 138, 171)'}} >
        Create new invoice
      </Button>

{/*
      <FormBtn
        component="invoice"
        disabled={isLoading} 
        style={{ marginBottom: 16, backgroundColor_: 'rgb(41, 138, 171)'}}
        type="primary" 
        action="add"
        icon_only={false}
        icon=""
        hint="Create a new draft invoice"
        label="invoice"
      />
*/}
      <Space direction="horizontal">
      <Button disabled={isLoading} type="primary" onClick={() => { /*setIsNeedUpdate(true);*/ fetchInvoices(tableParams.pagination.current, tableParams.pagination.pageSize);  }} style={{ marginBottom: 16, backgroundColor_: 'rgb(41, 138, 171)'}} >
        Reload
      </Button>
      <Typography.Text style={{ marginLeft: '10px', }}> Per Page: </Typography.Text>
      <Select
        disabled={isLoading}
        defaultValue={DEFAULT_PAGE_SIZE}
        style={{ width: 120, }}
        onChange={(e) => { setTableParams({ ...tableParams, pagination: { ...tableParams.pagination, pageSize: e }});  }}
        options={[
          { value: '5',label: '5', },
          { value: '10',label: '10', },
          { value: '15',label: '15', },
          { value: '20',label: '20', },
          { value: '100',label: '100', },
        ]}
      />
      </Space>
      </Space>
      <Table
        rowKey="id"
        size="small"
        dataSource={data}
        className={tableCSS}
        columns={columns}
        pagination={{
          ...tableParams.pagination,

// use global onChange
//          onChange: (page, pageSize) => handlePaginationChange({ current: page, pageSize }),
        }}
        onChange={handleTableChange}
        loading={isLoading}
        
      />
        <InvoiceForm 
           initialValues={(p_client_idx || p_client_idx === 0) ? {client_id: clients[p_client_idx]?.id} : {}} 
           invoice_id={invoiceId} 
           onSuccess={handleSuccess} 
           setIsNeedUpdate={setIsNeedUpdate}
        />
    </>
  );
};

export default InvoiceTable;
