import React, { useState, useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Form, Button } from 'antd';
import { getUserTenant } from "../../../auth/authHelper";
import { AUTH_TOKEN } from "../../../constant";

const { Dragger } = Upload;

/////////////////
const UploadCert = ({onSelectFile, botArgs, startUpload, ...otherProps}) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

const props = {
  name: 'file',
  accept: 'application/pdf',
  multiple: false, // single file at once
  action: process.env.REACT_APP_SERVER_URL + '/certificates/upload',
  data: {}, // filled later
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
  onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
  },
  beforeUpload: (file) => {
    setFileList([...fileList, file]);
    // MUST BE a single file! (props => multiple: false)
    if (onSelectFile) { 
      console.log('beforeUpload: find handler onSelectFile, try to call');
      try  { onSelectFile(file); } catch (e) {  alert(e); }
      console.log('beforeUpload: fateer handler onSelectFile');
    }    

    console.log('beforeUpload: return false for custom upload');
    return false;
  },
  fileList,
};

//////////////
const handleUpload = () => {
    alert(JSON.stringify(fileList));
    console.log("UploadCert.handleUpload: ", props);

debugger;

    const formData = new FormData();
    fileList.forEach((file) => {
      // formData.append('files[]', file);
      formData.append('file', file);
    });
    Object.keys(botArgs).map((key) => formData.append(key, botArgs[key]));
    //formData.append('data', JSON.stringify(botArgs));
    setUploading(true);

    // You can use any AJAX library you like
    fetch(props.action, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
      },
      //body: formData,
      data: formData,
    })
      .then((res) => res.json())
      .then((answer) => {

        if (answer.error) {
           console.log('UploadCert: handleUpload: server return error: ', answer);
           throw new Error('server return error: '+answer.error);
        }


// DISABLED for debug
//        setFileList([]);



        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
};

  useEffect(() => {
    if (fileList.length && startUpload) { 
      console.log('UploadCert: startUpload fired');
      handleUpload();
    }
  }, [startUpload]);

return (
<>
<Form.Item name="dragger" valuePropName="dragger">
  <Dragger {...props}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag certificate file to this area to upload</p>
    <p className="ant-upload-hint">You can load only one certificate file (*.pdf)</p>
  </Dragger>
</Form.Item>
{/*
  // switch to posponed upload via changing `startUpload` flag
  <Button
     type="primary"
     onClick={(e) => handleUpload(e) }
     disabled={fileList.length === 0}
     loading={uploading}
     style={{ marginTop: 16, }}
  >
     {uploading ? 'Uploading' : 'Start Upload'}
  </Button>
*/}
</>
)
};

export default UploadCert;



///
/*
const handleChange = res => {
    res.fileList.map(file => {
      if (file.response) {
        file.url =
          process.env.REACT_APP_SERVER_URL +
          "/storage/download/" +
          file.response.key;
      }
      return file;
    });

    if (res.file.status === "done") {
      let file = res.file;
      let objects = {
        name: file.name,
        size: file.size,
        key: file.response.key,
        uid: file.uid,
        last_modified: file.lastModifiedDate,
        type: file.type,
        bucket: file.response.Bucket,
        order_id: dataModel === "order" ? id : undefined,
        shipment_id: dataModel === "shipment" ? id : undefined
      };
      insert({
        variables: {
          objects: objects
        }
      });
    }
  };
*/